import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=b4e81cf8&scoped=true&"
import script from "./Spinner.vue?vue&type=script&lang=js&"
export * from "./Spinner.vue?vue&type=script&lang=js&"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=b4e81cf8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4e81cf8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4e81cf8')) {
      api.createRecord('b4e81cf8', component.options)
    } else {
      api.reload('b4e81cf8', component.options)
    }
    module.hot.accept("./Spinner.vue?vue&type=template&id=b4e81cf8&scoped=true&", function () {
      api.rerender('b4e81cf8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/shared/components/Spinner.vue"
export default component.exports