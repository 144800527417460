import { render, staticRenderFns } from "./360DialogWhatsapp.vue?vue&type=template&id=149e5f4e&"
import script from "./360DialogWhatsapp.vue?vue&type=script&lang=js&"
export * from "./360DialogWhatsapp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('149e5f4e')) {
      api.createRecord('149e5f4e', component.options)
    } else {
      api.reload('149e5f4e', component.options)
    }
    module.hot.accept("./360DialogWhatsapp.vue?vue&type=template&id=149e5f4e&", function () {
      api.rerender('149e5f4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/inbox/channels/360DialogWhatsapp.vue"
export default component.exports