var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"mentionsListContainerRef",staticClass:"bg-white dark:bg-slate-800 rounded-md overflow-auto absolute w-full z-20 pb-0 shadow-md left-0 bottom-full max-h-[9.75rem] border border-solid border-slate-100 dark:border-slate-700 mention--box"},[_c('ul',{staticClass:"mb-0 vertical dropdown menu"},_vm._l((_vm.items),function(item,index){return _c('woot-dropdown-item',{key:item.key,staticClass:"!mb-0",attrs:{"id":`mention-item-${index}`},on:{"mouseover":function($event){return _setup.onHover(index)}}},[_c('button',{staticClass:"flex group flex-col gap-0.5 overflow-hidden cursor-pointer items-start rounded-none py-2.5 px-2.5 justify-center w-full h-full text-left hover:bg-woot-50 dark:hover:bg-woot-800 border-x-0 border-t-0 border-b border-solid border-slate-100 dark:border-slate-700",class:{
          ' bg-woot-25 dark:bg-woot-800': index === _setup.selectedIndex,
        },on:{"click":function($event){return _setup.onListItemSelection(index)}}},[_vm._t("default",function(){return [_c('p',{staticClass:"max-w-full min-w-0 mb-0 overflow-hidden text-sm font-medium text-slate-900 dark:text-slate-100 group-hover:text-woot-500 dark:group-hover:text-woot-500 text-ellipsis whitespace-nowrap",class:{
              'text-woot-500 dark:text-woot-500': index === _setup.selectedIndex,
            }},[_vm._v("\n            "+_vm._s(item.description)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"max-w-full min-w-0 mb-0 overflow-hidden text-xs text-slate-500 dark:text-slate-300 group-hover:text-woot-500 dark:group-hover:text-woot-500 text-ellipsis whitespace-nowrap",class:{
              'text-woot-500 dark:text-woot-500': index === _setup.selectedIndex,
            }},[_vm._v("\n            "+_vm._s(_setup.variableKey(item))+"\n          ")])]},{"item":item,"index":index,"selected":index === _setup.selectedIndex})],2)])}),1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }