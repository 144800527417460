import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=3a4ef789&scoped=true&"
import script from "./ColorPicker.vue?vue&type=script&lang=js&"
export * from "./ColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=3a4ef789&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4ef789",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a4ef789')) {
      api.createRecord('3a4ef789', component.options)
    } else {
      api.reload('3a4ef789', component.options)
    }
    module.hot.accept("./ColorPicker.vue?vue&type=template&id=3a4ef789&scoped=true&", function () {
      api.rerender('3a4ef789', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/components/widgets/ColorPicker.vue"
export default component.exports